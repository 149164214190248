// Imports
import Vue from 'vue'
import Router from 'vue-router'
import { trailingSlash } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'
import store from '@/store'

Vue.use(Router)

//Pää tiedosto mihin rekisteröidään kaikki komponentit ja rakennetaan niille polut
//Samalla tässä tapahtuu käyttäjän oikeuksien validointi
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  },
  routes: [
      route('Login'),

      layout('Default', [
      // Dashboard
      route('Dashboard', null, '/dashboard'),

      // Pages
      route('UserProfile', null, '/profile'),

      route('Orders', null, '/orders'),

      route('Instructions', null, '/instructions'),

      // Components
      route('Notifications', null, '/notifications'),
      {
        path: '/users',
        component: () => import('@/views/Users'),
        beforeEnter: async (to, from, next) => {
          const resp = await store.dispatch('auth/checkAdmin')
          if (resp) {
            next()
          } else {
            next({ path: '/dashboard' })
          }
        }
      },
      {
        path: '/organisations',
        component: () => import('@/views/Organisations'),
        beforeEnter: async (to, from, next) => {
          const resp = await store.dispatch('auth/checkAdmin')
          if (resp) {
            next()
          } else {
            next({ path: '/dashboard' })
          }
        }
      },
      {
        path: '/order/:id',
        name: 'OrderView',
        component:  () => import('@/views/OrderView'),
      }
    ]),
  ],
})

router.beforeEach((to, from, next) => {
  return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
})


export default router
