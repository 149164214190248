import {getFirestore, doc, getDoc, query, getDocs, collection, where } from "firebase/firestore";

//Store/State tiedosto joka vastaa taulukkoiden datan hausta

const state = {
  orders: [],
  order: {},
  organisations: [],
  neworders: [],
  notifications: [],
  users: []
}

const mutations = {
  setData(state, data) {
    if (data.length) {
      state.orders = data.sort((a, b) => b.deliverydate - a.deliverydate);
      state.neworders = data.filter(obj => (obj.orderstatus == 'new' || obj.orderstatus == 'open')).sort((a, b) => b.createdAt - a.createdAt);
    } else {
      state.orders = []
    }
  },
  setNotifications(state, notififications_data) {
    state.notifications = notififications_data
  },
  setOrder(state, order_data) {
    state.order = order_data
  },
  setUsers(state, users_data) {
    state.users = users_data
  },
  setOrganisations(state, organisations_data) {
    state.organisations = organisations_data
  }
}

const actions = {
  //Action joka hakea dataa tietokannasta tietylle käyttäjälle
  async fetchData ({ commit }, partyID) {
    commit('setData', '')
    const db = getFirestore()
    let q = query(collection(db, "orders"), where("supplierid", "==", partyID));
    let querySnapshot = await getDocs(q);
    if(!querySnapshot.docs.length){
      q = query(collection(db, "orders"), where("customerid", "==", partyID));
      querySnapshot = await getDocs(q);
    }
    let data = []
    querySnapshot.forEach((doc) => {
      const obj = doc.data()
      data.push(obj)
    })
    if (data.length) {
      commit('setData', data)
    }
  },

  async fetchAdmin({commit}) {
    const db = await getFirestore()
    const querySnapshotOrders = await getDocs(collection(db, "orders"));
    let data = []
    querySnapshotOrders.forEach((doc) => {
      const obj = doc.data()
      data.push(obj)
    })

    if (data.length) {
      commit('setData', data)
    }
  },
  //Action joka hakee käyttäjiä tietokannasta
  async fetchUsers ({ commit }) {
    const db = await getFirestore()
    const querySnapshotUsers = await getDocs(collection(db, "users"));
    const querySnapshotOrgs = await getDocs(collection(db, 'organisations'))

    let usersData = []
    let orgsData = []


    querySnapshotUsers.forEach(( doc) => {
      const obj = doc.data()
      obj.id = doc.id
      usersData.push(obj)
    })

    querySnapshotOrgs.forEach((doc) => {

      const obj = doc.data()
      obj.id = doc.id
      //const orgid = obj.organisation.path.replace("organisations/", "")
      orgsData.push(obj)
    })

    for(let i = 0; i < usersData.length; i++) {
      for(let j = 0; j < orgsData.length; j++) {
        if (usersData[i].organisation.id == orgsData[j].id) {
          usersData[i].organisation = orgsData[j]
        }
      }
    }

    if (usersData.length) {
      commit('setUsers', usersData)
    }
  },

  async fetchNofitications({commit}) {
    const db = getFirestore()
    const q = query(collection(db, "notifications"));
    const querySnapshot = await getDocs(q);
    let data = []
    querySnapshot.forEach((doc) => {
      const obj = doc.data()
      data.push(obj)
    })
    if (data.length) {
      data.sort(function(a, b) {
        return b.date - a.date;
      });
      commit('setNotifications', data)
    }
  },

  //Action joka hakea tietty orderi ID perusteella
  async fetchOrder ({ commit }, id) {
    const db = await getFirestore()
    const docRef = doc(db, "orders", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      commit('setOrder', docSnap.data())
    }
  },
  //Action joka hakea kaikki organisaatiot
  async fetchOrganisations ({ commit }) {
    const db = getFirestore()
    const q = query(collection(db, "organisations"));
    const querySnapshot = await getDocs(q);
    let data = []
    querySnapshot.forEach((doc) => {
      const obj = doc.data()
      data.push(obj)
    })
    if (data.length) {
      commit('setOrganisations', data)
    }
  }

}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
