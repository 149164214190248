<template>
  <v-container>
    <v-container v-if="!organisations.length" style="height: 400px;">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
          Ladataan tietoja...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="blue accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
    <material-card
      v-else
      icon="mdi-home-group"
      icon-small
      color="accent"
      title="Organisaatiot"
      class="d-flex flex-wrap"
    >
      <v-card-title class="search_block">
        <v-spacer></v-spacer>
        <div class="search_field">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="langs[localization].search"
            single-line
            hide-details
          ></v-text-field>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="organisations"
        :search="search"
        :footer-props="{
             'items-per-page-text': langs[localization].rowsPerPage
        }"
      >
        <template v-slot:item.orgtype="{ item }">
          <span v-if="item.orgtype == 'supplier'">{{ langs[localization].supplier }}</span>
          <span v-else> {{ langs[localization].customer }} </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-dialog
            transition="dialog-top-transition"
            max-width="600"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="3"
                icon
                small
                v-bind="attrs"
                v-on="on"
              ><v-icon
                :item="item"
              >mdi-pencil</v-icon>
              </v-btn>
            </template>
            <template v-slot:default="dialogUpdateOrg">
              <v-card>
                <v-toolbar
                  :dialog="dialogUpdateOrg"
                  color="primary"
                  dark
                >{{ langs[localization].updateOrganisation }}</v-toolbar>
                <v-card-text>
                  <div class="text-h2 pa-12">
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                      class=""
                    >
                      <v-row>
                        <v-col
                          cols="6"
                        >
                          <v-text-field
                            :value="item.name"
                            @input="getname($event, item.name)"
                            :rules="computed_nameRules"
                            :label="langs[localization].name"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="6"
                        >
                          <v-select
                            :items="orgTypes"
                            item-text="value"
                            item-value="id"
                            v-model="item.orgtype"
                            @input="getorgtype($event, item.orgtype)"
                            :rules="computed_nameRules"
                            required
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="6"
                        >
                          <v-text-field
                            :value="item.partyid"
                            @input="getpartyid($event, item.partyid)"
                            :rules="computed_nameRules"
                            :label="langs[localization].partyid"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-textarea
                            solo
                            :value="item.info"
                            name="input-7-4"
                            @input="getinfo($event, item.info)"
                            :label="langs[localization].notes"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-form>
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    color="blue darken-1"
                    class="mr-2 mb-4"
                    text
                    @click="closeUpdateDialog(dialogUpdateOrg)"
                  >
                    {{ langs[localization].cancel }}
                  </v-btn>
                  <v-btn
                    :disabled="!valid"
                    color="primary"
                    class="mr-4 mb-4"
                    @click="updateOrg(item, dialogUpdateOrg)"
                  >
                    {{ langs[localization].accept }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </template>
      </v-data-table>
      <v-row justify="center">
        <v-dialog
          v-model="dialogAddOrg"
          persistent
          max-width="600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="mb-3"
              dark
              v-bind="attrs"
              v-on="on"
            >
              {{ langs[localization].addOrganisation }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ langs[localization].newOrganisation }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="newOrg.name"
                        :label="langs[localization].organisationName"
                        :rules="computed_nameRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="newOrg.partyid"
                        :label="langs[localization].partyid + '*'"
                        :rules="computed_nameRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="newOrg.orgtype"
                        :items="orgTypes"
                        item-value="id"
                        item-text="value"
                        :rules="[v => !!v || langs[localization].fieldIsRequired ]"
                        :label="langs[localization].organisationType"
                        required
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-textarea
                        solo
                        name="input-7-4"
                        v-model="newOrg.info"
                        :label="langs[localization].notes"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <small class="ml-3">{{ langs[localization].mandatoryField }}</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeDialog"
              >
                {{ langs[localization].cancel }}
              </v-btn>
              <v-btn
                :disabled="!valid"
                color="primary"
                dark
                @click="createOrg"
              >
                {{ langs[localization].save }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </material-card>
    <v-row>
      <v-dialog
        v-model="errorDialog"
        max-width="600px"
      >
        <v-card>
          <v-card-title class="justify-center">
            <p>{{ errorMessage }}</p>
            <v-spacer></v-spacer>
            <v-menu
              bottom
              right
            >
            </v-menu>
          </v-card-title>
          <v-card-actions class="justify-end">
            <v-btn
              color="primary"
              @click="errorDialog = false; errorMessage = ''"
            >
              {{ langs[localization].close }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import translate from '@/plugins/translation'

export default {
  //Komponentti joka kaikki organisaatiot
  name: 'ViewOrganisations',
  data: function () {
    return {
      langs: translate,
      dialogUpdateOrg: false,
      dialogAddOrg: false,
      search: '',
      errorMessage: '',
      errorDialog: false,
      updatedOrg: {},
      valid: true,
      newOrg: {
        info: '',
        name: '',
        partyid: '',
        orgtype: ''
      },
      select: null
    }
  },
  computed: {
    ...mapState({
      organisations: state => state.tables.organisations,
      localization: state => state.auth.localization
    }),
    headers() {
      let headers = [
          {
            text: this.langs[this.localization].organisation,
            value: 'name',
            sortable: false
          },
          {
            text: this.langs[this.localization].type,
            value: 'orgtype',
            sortable: true
          },
          {
            text: this.langs[this.localization].partyid,
            value: 'partyid',
            sortable: false
          },
          {
            text: this.langs[this.localization].notes,
            value: 'info',
            sortable: false,
            width: '60%'
          },
          {
            value: 'actions',
            sortable: false,
          }
        ]
      return headers
    },
    computed_nameRules() {
      return [
        v => !!v || this.langs[this.localization].fieldIsRequired,
        v => (v && v.length <= 50) || this.langs[this.localization].userNameRules
      ]
    },
    orgTypes() {
      let orgTypes = [
        {id: 'supplier', value: this.langs[this.localization].supplier},
        {id: 'purchaser', value: this.langs[this.localization].customer}
      ]
      return orgTypes
    }
  },
  methods: {
    async createOrg() {
      if (this.newOrg.orgtype == 'Toimittaja') {
        this.newOrg.orgtype = 'supplier'
      } else {
        this.newOrg.orgtype = 'purchaser'
      }
      let resp = await this.$store.dispatch('create/createOgranisation', {
        info: this.newOrg.info,
        name: this.newOrg.name,
        orgtype: this.newOrg.orgtype,
        partyid: this.newOrg.partyid,
      })
      if(resp) {
        this.errorMessage = resp
        this.errorDialog = true
      }
      await this.$store.dispatch('tables/fetchOrganisations')
      this.closeDialog()
    },
    getname(evt, name) {
      this.updatedOrg.name = evt ? evt : name
    },
    getorgtype(evt, orgtype) {
      this.updatedOrg.orgtype = evt ? evt : orgtype
    },
    getpartyid(evt, partyid) {
      this.updatedOrg.partyid = evt ? evt : partyid
    },
    getinfo(evt, info) {
      this.updatedOrg.info = evt ? evt : info
    },
    async updateOrg (item, dialogUpdateOrg) {
      if (this.$refs.form.validate()) {
        if (!this.updatedOrg.name) {
          this.updatedOrg.name = item.name
        }

        if (!this.updatedOrg.orgtype) {
          this.updatedOrg.orgtype = item.orgtype
        }

        if (!this.updatedOrg.partyid) {
          this.updatedOrg.partyid = item.partyid
        }

        if (!this.updatedOrg.info) {
          this.updatedOrg.info = item.info
        }
        await this.$store.dispatch('update/updateOrg', {
          name: this.updatedOrg.name,
          orgtype: this.updatedOrg.orgtype,
          newpartyid: this.updatedOrg.partyid,
          oldpartyid: item.partyid,
          info: this.updatedOrg.info
        })

        this.$store.dispatch('tables/fetchOrganisations');

        this.updatedOrg.name = ''
        this.updatedOrg.orgtype = ''
        this.updatedOrg.partyid = ''
        this.updatedOrg.info = ''

        dialogUpdateOrg.value = false
      }
    },
    closeDialog() {
      this.newOrg = {
        info: '',
        name: '',
        partyid: '',
        orgtype: ''
      }
      this.dialogAddOrg = false
    },
    closeUpdateDialog(dialogUpdateOrg) {
      dialogUpdateOrg.value = false
    }
  }
}
</script>

<style scoped>

.v-card__title {
  font-size: 1.50rem !important;
}
</style>
