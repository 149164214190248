<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="grey"
          elevation="2"
          icon
          small
          v-bind="attrs"
          v-on="on"
          class="mt-4 ml-2"
        >
          <v-icon
            dark
          >
            mdi-view-column
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>{{ langs[localization].columns }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-checkbox
                input-value="true"
                disabled
                :label="langs[localization].line"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="checkboxIndex['productno_supplier']"
                :label="langs[localization].productNumber"
                color="indigo darken-3"
                :value="true"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="checkboxIndex['eanbatch']"
                label="EAN"
                color="indigo darken-3"
                :value="true"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-checkbox
                v-model="checkboxIndex['productname']"
                :label="langs[localization].productName"
                color="indigo darken-3"
                :value="true"
                hide-details
              ></v-checkbox>
              <v-checkbox
                input-value="true"
                disabled
                :label="langs[localization].orderAmount"
                hide-details
              ></v-checkbox>
              <v-checkbox
                input-value="true"
                disabled
                :label="langs[localization].confirmedAmount"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-checkbox
                v-model="checkboxIndex['unit']"
                :label="langs[localization].unit"
                color="indigo darken-3"
                :value="true"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="checkboxIndex['unitprice']"
                :label="langs[localization].price"
                color="indigo darken-3"
                :value="true"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="checkboxIndex['rowsum']"
                :label="langs[localization].summ"
                color="indigo darken-3"
                :value="true"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-checkbox
                input-value="true"
                disabled
                :label="langs[localization].deliveryDate"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="checkboxIndex['notes']"
                :label="langs[localization].notes"
                color="indigo darken-3"
                :value="true"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
            color="blue darken-1"
            text
            @click="defaultHeaders()"
          >
            {{ langs[localization].defaultView }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            {{ langs[localization].close }}
          </v-btn>
          <v-btn
            color="primary"
            @click="updateHeaders"
          >
            {{ langs[localization].save }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import translation from '@/plugins/translation'

export default {
  name: 'HeadersDialog',
  data () {
    return {
      langs: translation,
      dialogm1: '',
      dialog: false,
      checkboxIndex: [],
      headers: [
        {
          text: 'line',
          value: 'id',
        },
        {
          text: 'productNumber',
          value: 'productno_supplier'
        },
        {
          text: 'EAN',
          value: 'eanbatch'
        },
        {
          text: 'productName',
          value: 'productname'
        },
        {
          text: 'orderAmount',
          value: 'order_amount'
        },
        {
          text: 'confirmedAmount',
          value: 'confirmed_amount'
        },
        {
          text: 'unit',
          value: 'unit'
        },
        {
          text: 'price',
          value: 'unitprice'
        },
        {
          text: 'summ',
          value: 'rowsum'
        },
        {
          text: 'deliveryDate',
          value: 'deliverydate'
        },
        {
          text: 'notes',
          value: 'notes',
        }
      ]
    }
  },
  mounted () {
    this.computeHeaders()
  },
  computed: {
    ...mapState({
      stateHeaders: state => state.auth.headers,
      localization: state => state.auth.localization
    })
  },
  methods: {
    updateHeaders() {
      let arr = []
      for (let i = 0; i < this.headers.length; i++) {
        if (this.checkboxIndex[this.headers[i].value] == true) {
          arr.push(this.headers[i])
        }
      }
      this.$store.dispatch('auth/updateHeaders', {
          headers: arr
      })
      this.dialog = false
      this.$store.dispatch('update/updateUserHeaders', {
        headers: this.stateHeaders
      })
    },
    computeHeaders () {
      let newHeaders = this.stateHeaders
      for(let i = 0; i < newHeaders.length; i++) {
        this.checkboxIndex[newHeaders[i].value] = true
      }
    },
    defaultHeaders() {
      this.$store.dispatch('auth/updateHeaders', {
        headers: this.headers
      })
      this.dialog = false
      this.$store.dispatch('update/updateUserHeaders', {
        headers: this.headers
      })
      this.computeHeaders()
    }
  }
}
</script>

<style scoped>
.edit_columns_btn {
  padding-top: 12px;
  margin-top: 4px;
}
</style>
