<template>
  <v-container>
    <v-container v-if="!users.length" style="height: 400px;">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
          Ladataan tietoja...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="blue accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
    <material-card
      v-else
      icon="mdi-account-group"
      icon-small
      color="accent"
      :title="langs[localization].users"
      class="d-flex flex-wrap"
    >
      <v-card-title class="search_block">
        <v-spacer></v-spacer>
        <div class="search_field">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="langs[localization].search"
            single-line
            hide-details
          ></v-text-field>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        :footer-props="{
             'items-per-page-text': langs[localization].rowsPerPage
        }"
      >
        <template v-slot:item.organisatio="{ item }">
          {{ item.organisation.name }}
        </template>

        <template v-slot:item.disabled="{ item }"
        >
          <td v-if="item.disabled == true" class="text-start">
            {{ langs[localization].disabled }}
          </td>
          <td v-else class="text-start">
            {{ langs[localization].active }}
          </td>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-dialog
            transition="dialog-top-transition"
            max-width="600"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="3"
                icon
                small
                v-bind="attrs"
                v-on="on"
                  ><v-icon
                    :item="item"
                  >mdi-pencil</v-icon>
              </v-btn>
            </template>

            <template v-slot:default="dialogUpdateUser">
              <v-card>
                <v-toolbar
                  :dialog="dialogUpdateUser"
                  color="primary"
                  dark
                >{{ langs[localization].updateUser }} - {{item.organisation.name}}</v-toolbar>
                <v-card-text>
                  <div class="text-h2 pa-12">
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                      class=""
                    >
                      <v-row>
                        <v-col
                        cols="6"
                        >
                          <v-text-field
                            :value="item.firstname"
                            @input="getfirstname($event, item.firstname)"
                            :rules="nameRules"
                            :label="langs[localization].firstname"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="6"
                        >
                          <v-text-field
                            :value="item.lastname"
                            @input="getlastname($event, item.lastname)"
                            :rules="nameRules"
                            :label="langs[localization].lastname"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="6"
                        >
                          <v-text-field
                            :value="item.phone"
                            @input="getphone($event, item.phone)"
                            type="tel"
                            pattern="[0-9]{7,10}"
                            :rules="phoneRules"
                            :label="langs[localization].phoneNumber"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="6"
                        >
                          <v-text-field
                            :value="item.email"
                            @input="getemail($event, item.email)"
                            :rules="emailRules"
                            :label="langs[localization].username"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="6"
                        >
                          <v-select
                            v-model="item.disabled"
                            :items="items"
                            item-value="id"
                            item-text="value"
                            :label="langs[localization].status"
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="6 d-flex">
                          <v-text-field
                            v-model="newPassword"
                            :label="langs[localization].password"
                            required
                          ></v-text-field>
                          <v-btn
                            class="align-self-center ml-2"
                            x-small
                            @click="generateNewPass"
                          >
                            <v-icon
                              small
                            >
                              mdi-refresh
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    :disabled="!valid"
                    color="blue darken-1"
                    class="mr-2 mb-4"
                    text
                    @click="closeUpdateDialog(dialogUpdateUser)"
                  >
                    {{ langs[localization].cancel }}
                  </v-btn>
                  <v-btn
                    :disabled="!valid"
                    color="primary"
                    class="mr-4 mb-4"
                    @click="updateUser(item.id, item.firstname, item.lastname, item.email, item.phone, item.username, item.password, item.disabled, dialogUpdateUser)"
                  >
                    {{ langs[localization].accept }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </template>
      </v-data-table>
      <v-row justify="center">
        <template>
          <v-dialog
            v-model="dialogAddUser"
            persistent
            max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mb-3"
                dark
                v-bind="attrs"
                v-on="on"
                @click="fetchOrganisations"
              >
                {{ langs[localization].addUser }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ langs[localization].newUser }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="newUser.firstname"
                          :label="langs[localization].firstname + '*'"
                          :rules="computed_nameRules"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="newUser.lastname"
                          :label="langs[localization].lastname + '*'"
                          :rules="computed_nameRules"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="newUser.username"
                          :label="langs[localization].emailAddress + '*'"
                          :rules="computed_emailRules"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="newUser.phone"
                          :label="langs[localization].phoneNumber + '*'"
                          :rules="computed_phoneRules"
                          type="tel"
                          pattern="[0-9]{7,10}"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-autocomplete
                          v-model="newUser.organisation"
                          :items="orgNames"
                          :rules="[v => !!v || langs[localization].fieldIsRequired]"
                          :label="langs[localization].organisation + '*'"
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="6 d-flex">
                        <v-text-field
                          v-model="newUser.password"
                          :label="langs[localization].password + '*'"
                          required
                        ></v-text-field>
                        <v-btn
                          class="align-self-center ml-2"
                          x-small
                          @click="generatePass"
                        >
                          <v-icon
                            small
                          >
                            mdi-refresh
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <small class="ml-3">{{ langs[localization].mandatoryField }}</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeDialog"
                >
                  {{ langs[localization].cancel }}
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  dark
                  @click="saveUser"
                >
                  {{ langs[localization].save }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-row>
    </material-card>
    <v-row>
      <v-dialog
        v-model="errorDialog"
        max-width="500px"
      >
        <v-card>
          <v-card-title class="justify-center">
            <p>{{ errorMessage }}</p>
            <v-spacer></v-spacer>
            <v-menu
              bottom
              right
            >
            </v-menu>
          </v-card-title>
          <v-card-actions class="justify-end">
            <v-btn
              color="primary"
              @click="errorDialog = false; errorMessage = ''"
            >
              {{ langs[localization].close }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
//Komponentti joka näyttää käyttäjiä
import { mapState } from 'vuex'
import translate from '@/plugins/translation'


export default {
  name: 'ViewUsers',
  data: function () {
    return {
      langs: translate,
      dialogUpdateUser: false,
      dialogAddUser: false,
      newPassword: '',
      search: '',
      errorMessage: '',
      errorDialog: false,
      valid: true,
      newUser: {
        firstname: '',
        lastname: '',
        username: '',
        phone: '',
        organisation: '',
        password: ''
      },
      userinfo: {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        password: ''
      },
      select: null,
      orgNames: []
    }
  },
  computed: {
    ...mapState({
      users: state => state.tables.users,
      organisations: state => state.tables.organisations,
      localization: state => state.auth.localization
    }),
    headers() {
      let headers = [
        {
          text: this.langs[this.localization].organisation,
          value: 'organisatio',
        },
        {
          text: this.langs[this.localization].firstname,
          value: 'firstname',
        },
        {
          text: this.langs[this.localization].lastname,
          value: 'lastname',
        },
        {
          text: this.langs[this.localization].username,
          value: 'username',
        },
        {
          text: this.langs[this.localization].phoneNumber,
          value: 'phone',
        },
        {
          text: this.langs[this.localization].status,
          value: 'disabled',
        },
        {
          value: 'actions',
          sortable: false,
        }
      ]
      return headers
    },
    computed_nameRules() {
      return [
        v => !!v || this.langs[this.localization].fieldIsRequired,
        v => (v && v.length <= 50) || this.langs[this.localization].userNameRules
      ]
    },
    computed_emailRules() {
      return [
        v => !!v || this.langs[this.localization].fieldIsRequired,
        v => /.+@.+\..+/.test(v) || this.langs[this.localization].userEmailRule
      ]
    },
    computed_phoneRules() {
      return [
        v => !!v || this.langs[this.localization].fieldIsRequired,
        v => (v && v.length == 10) || this.langs[this.localization].userPhoneRule
      ]
    },
    items() {
      let items = [
          {id: false, value: this.langs[this.localization].active },
          {id: true, value: this.langs[this.localization].disabled }
        ]
      return items
    }
  },
  methods: {
    getfirstname(evt, firstname) {
      this.userinfo.firstname = evt ? evt : firstname
    },
    getlastname(evt, lastname) {
      this.userinfo.lastname = evt ? evt : lastname
    },
    getemail(evt, email) {
      this.userinfo.email = evt ? evt : email
    },
    getphone(evt, phone) {
      this.userinfo.phone = evt ? evt : phone
    },
    getpassword(evt, password) {
      this.userinfo.phone = evt ? evt : password
    },
    generatePass() {
      this.newUser.password = Array(20).fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!_?&").map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
      },
    generateNewPass() {
      this.newPassword = Array(20).fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!_?&").map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
    },
    async updateUser (id, firstname, lastname, email, phone, username, oldpassword, disabled, dialogUpdateUser) {
      if (this.$refs.form.validate()) {
        dialogUpdateUser.value = false
        try {
          if (!this.userinfo.firstname) {
            this.userinfo.firstname = firstname
          }

          if (!this.userinfo.lastname) {
            this.userinfo.lastname = lastname
          }

          if (!this.userinfo.email) {
            this.userinfo.email = email
          }

          if (!this.userinfo.phone) {
            this.userinfo.phone = phone
          }

          if(this.newPassword == '') {
            this.userinfo.password = oldpassword
          } else {
            this.userinfo.password = this.newPassword
          }

          const idToken = await this.$store.dispatch('create/fetchToken')
          await this.$store.dispatch('update/updatePass', {token: idToken, pass: this.userinfo.password, uid: id})
          await this.$store.dispatch('update/updateUser', {
            username: username,
            firstname: this.userinfo.firstname,
            lastname: this.userinfo.lastname,
            phone: this.userinfo.phone,
            email: this.userinfo.email,
            password: this.userinfo.password,
            disabled: disabled
          })
          await this.$store.dispatch('tables/fetchUsers');

          this.userinfo.firstname = ''
          this.userinfo.lastname = ''
          this.userinfo.email = ''
          this.userinfo.phone = ''
          this.newPassword = ''
        } catch (e) {
          console.log(e)
        }
      }
    },
    closeUpdateDialog(dialogUpdateUser) {
      dialogUpdateUser.value = false
      this.newPassword = ''
    },
    reset() {
      this.$refs.form.reset()
    },
    async fetchOrganisations() {
      await this.$store.dispatch('tables/fetchOrganisations')
      this.organisations.forEach(el => {
        this.orgNames.push(el.name)
      })
    },
    async saveUser() {
      const idToken = await this.$store.dispatch('create/fetchToken')
      if (this.$refs.form.validate()){
        this.dialogAddUser = false
        let resp = await this.$store.dispatch('create/createUser', {token: idToken, user: this.newUser})
        if(resp) {
          this.errorMessage = resp
          this.errorDialog = true
        }
        this.$store.dispatch('tables/fetchUsers');
      }
      this.newUser = {
        firstname: '',
        lastname: '',
        username: '',
        phone: '',
        organisation: '',
        password: ''
      }
    },
    closeDialog() {
      this.newUser = {
        firstname: '',
        lastname: '',
        username: '',
        phone: '',
        organisation: '',
        password: ''
      }
      this.dialogAddUser = false
    }
  }
}
</script>

<style scoped>

.v-card__title {
  font-size: 1.50rem !important;
}
</style>
