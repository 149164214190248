<template>
  <v-container>
    <material-card
      icon="mdi-clipboard-text"
      icon-small
      color="accent"
      :title="langs[localization].allOrders"
      class="d-flex flex-wrap"
    >
      <v-card-title class="search_block">
        <v-spacer></v-spacer>
        <div class="search_field d-flex">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="langs[localization].search"
            single-line
            hide-details
          ></v-text-field>
          <OrderHeadersDialog/>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="orders"
        :search="search"
        :footer-props="{
             'items-per-page-text': langs[localization].rowsPerPage
        }"
      >
        <template v-slot:item.orderdate="{ item }">
            {{ formatDate(item.orderdate) }}
        </template>
        <template v-slot:item.deliverydate="{ item }">
          {{ formatDate(item.deliverydate) }}
        </template>
        <template v-slot:item.invoicecustomer="{ item }">
          {{ item.invoicecustomer.name }}
        </template>
        <template v-slot:item.orderstatus="{ item }">
          <v-chip
            v-if="item.orderstatus == 'new'"
            color="red"
            label
          >
            {{ langs[localization].new }}
          </v-chip>
          <v-chip
            v-if="item.orderstatus == 'open'"
            color="blue"
            label
          >
            {{ langs[localization].open }}
          </v-chip>
          <v-chip
            v-if="item.orderstatus == 'canceled'"
            label
          >
            {{ langs[localization].canceled }}
          </v-chip>
          <v-chip
            v-if="item.orderstatus == 'delivered'"
            label
            color="green"
          >
            {{ langs[localization].delivered }}
          </v-chip>
          <v-chip
            v-if="item.orderstatus == 'accepted'"
            label
            color="yellow"
          >
            {{ langs[localization].accepted }}
          </v-chip>
          <v-chip
            v-if="item.orderstatus == 'confirmed'"
            label
            color="cyan lighten-2"
          >
            {{ langs[localization].confirmed }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <td class="d-flex">
            <router-link :to="{ name: 'OrderView', params: {id: item.orderid}}">
              <v-btn
                class="ml-2 mt-3"
                height="23"
                color="primary"
              >
                {{ langs[localization].open }}
              </v-btn>
            </router-link>
            <v-btn
              class="ml-2 mt-3"
              height="23"
              color="primary"
              @click="printDoc(item)"
            >
              {{ langs[localization].print }}
            </v-btn>
            <v-btn
              v-if="admin"
              class="ma-2"
              fab
              dark
              x-small
              color="primary"
              @click="dropTimestamps(item.orderid)"
            >
              <v-icon dark>
                mdi-update
              </v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import translate from '@/plugins/translation'
import OrderHeadersDialog from '@/components/OrderHeadersDialog'

export default {
  //Komponentti joka näyttää kaikki tilauksia
  name: 'ViewAllOrders',
  components: {
    OrderHeadersDialog
  },
  data: function () {
    return {
      langs: translate,
      search: '',
    }
  },
  computed: {
    ...mapState({
      orders: state => state.tables.orders,
      admin: state => state.auth.admin,
      localization: state => state.auth.localization
    }),
    ...mapGetters({
      headers: 'auth/getOrdersHeaders'
    }),
  },
  methods: {
    formatDate(timestamp) {
      moment.locale('fi');
      if(typeof timestamp !== 'object') {
        return moment(timestamp).format('L')
      } else {
        const date = new Date(timestamp.seconds * 1000).toISOString().substr(0, 10)
        return moment(date).format('L')
      }
    },
    printDoc(item) {
      let WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      let delivery = {}
      let invoice = {}
      let ordersumm = 0
      item.orderrows.forEach(el => {
        ordersumm += el.unitprice * el.confirmed_amount
      })

      if (!item.deliverysite) {
        delivery.name = item.deliverycustomer.name
        delivery.adress = item.deliverycustomer.streetadress ? item.deliverycustomer.streetadress : ''
        delivery.city = item.deliverycustomer.city ? item.deliverycustomer.city : ''
        delivery.id = item.deliverycustomer.deliverycustomerid ? item.deliverycustomer.deliverycustomerid : ''
      } else {
        delivery.name = item.deliverysite.name
        delivery.adress = item.deliverysite.streetadress ? item.deliverysite.streetadress : ''
        delivery.city = item.deliverysite.city ? item.deliverysite.city : ''
        delivery.id = item.deliverysite.deliverysiteid ? item.deliverysite.deliverysiteid : ''
      }

      if (!item.invoicecustomer) {
        invoice.name = item.deliverycustomer.name
        invoice.adress = item.deliverycustomer.streetadress ? item.deliverycustomer.streetadress : ''
        invoice.city = item.deliverycustomer.city ? item.deliverycustomer.city : ''
        invoice.id = item.deliverycustomer.deliverycustomerid ? item.deliverycustomer.deliverycustomerid : ''
      } else {
        invoice.name = item.invoicecustomer.name
        invoice.adress = item.invoicecustomer.streetadress ? item.invoicecustomer.streetadress : ''
        invoice.city = item.invoicecustomer.city ? item.invoicecustomer.city : ''
        invoice.id = item.invoicecustomer.invoicecustomerid ? item.invoicecustomer.invoicecustomerid : ''
      }

      WinPrint.document.write(`
      <!DOCTYPE html>
      <html>
        <style>
        body {
            size: A4;
            margin: 0;
        }
        .content {
            width: 100%;
        }
        .row {
            display: flex;
            margin: 0 20px;
            justify-content: space-between;
            border-bottom: 1px solid #333333;
        }
        .col {
            width: 25%;
        }
        h4 {
            margin: 10px 0 10px 0;
            padding: 0px;
        }
        p {
            margin: 4px 0 4px 0;
        }
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
        li {
            padding: 0px
        }
        table {
            width: 95%;
            margin: 10px auto 0 auto;
            text-align: center;
            border: solid 1px #DDEEEE;
            border-collapse: collapse;
            border-spacing: 0;
        }
        th {
            font-size: 12px;
            padding: 5px;
            border: solid 1px #DDEEEE;
        }
        td {
            font-size: 10px;
            padding: 5px;
            border: solid 1px #DDEEEE;
        }
        </style>
        <body>
          <div class="content">
            <div class="row">
                <div class="col">
                    <h4>${this.langs[this.localization].deliveryAddress}</h4>
                    <ul>
                      <li><p>${delivery.name}</p></li>
                      <li><p>${delivery.adress}</p></li>
                      <li><p>${delivery.city}</p></li>
                      <li><p>ID: ${delivery.id}</p></li>
                    </ul>
                </div>
                <div class="col">
                    <h4>${this.langs[this.localization].orderDate}</h4>
                    <p>${moment(new Date(item.orderdate.seconds * 1000)).format('L')}</p>
                </div>
                <div class="col">
                    <h4>${this.langs[this.localization].desiredDeliveryDate}</h4>
                    <p>${moment(new Date(item.deliverydate.seconds * 1000)).format('L')}</p>
                </div>
                <div class="col">
                    <h4>${this.langs[this.localization].orderTotal}</h4>
                    <p>${ ordersumm } €</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h4>${this.langs[this.localization].billingInformation}</h4>
                    <ul>
                      <li><p>${invoice.name}</p></li>
                      <li><p>${invoice.adress}</p></li>
                      <li><p>${invoice.city}</p></li>
                      <li><p>ID: ${invoice.id}</p></li>
                    </ul>
                </div>
                <div class="col">
                    <h4>${this.langs[this.localization].orderReference}</h4>
                    <p>${item.customerref}</p>
                </div>
                <div class="col">
                    <h4>${this.langs[this.localization].orderNumber}</h4>
                    <p>${item.ordernumber}</p>
                </div>
                <div class="col">
                    <h4>${this.langs[this.localization].deliveryDate}</h4>
                    <p>${moment(new Date(item.confirmeddeliverydate.seconds * 1000)).format('L')}</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h4>${this.langs[this.localization].orderDetails}</h4>
                    <p>${item.ordernotes}</p>
                </div>
            </div>
            <table class="table">
              <tr>
                <th>${this.langs[this.localization].line}</th>
                <th>${this.langs[this.localization].productNumber}</th>
                <th>EAN</th>
                <th>${this.langs[this.localization].productName}</th>
                <th>${this.langs[this.localization].orderAmount}</th>
                <th>${this.langs[this.localization].confirmedAmount}</th>
                <th>${this.langs[this.localization].unit}</th>
                <th>${this.langs[this.localization].price}</th>
                <th>${this.langs[this.localization].summ}</th>
                <th>${this.langs[this.localization].deliveryDate}</th>
                <th>${this.langs[this.localization].notes}</th>
              </tr>
            </table>
          </div>
        </body>
      </html>`);

      let tbody = WinPrint.document.getElementsByTagName('tbody')
      for(let i = 0; i < item.orderrows.length; i++) {
        let tr = document.createElement('tr')
        tr.innerHTML = `
            <td>${i + 1}</td>
            <td>${item.orderrows[i].productno_supplier}</td>
            <td>${item.orderrows[i].eancode}</td>
            <td>${item.orderrows[i].productname}</td>
            <td>${item.orderrows[i].order_amount}</td>
            <td>${item.orderrows[i].confirmed_amount}</td>
            <td>${item.orderrows[i].unit}</td>
            <td>${item.orderrows[i].unitprice} €</td>
            <td>${item.orderrows[i].unitprice * item.orderrows[i].confirmed_amount} €</td>
            <td>${moment(new Date(item.orderrows[i].deliverydate.seconds * 1000)).format('L')}</td>
            <td>${item.orderrows[i].notes}</td>`
        tbody.item(0).appendChild(tr)
      }

      WinPrint.document.title = `order_${item.ordernumber}`
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    dropTimestamps(orderid) {
      this.$store.dispatch('update/dropTimestamps', {'orderid': orderid})
    }
  }
}
</script>

<style>
.v-card__title {
  font-size: 1.50rem !important;
}

.search_block {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .search_block {
    width: 100%;
  }
}
</style>
