import {
  collection,
  doc, getDocs,
  addDoc,
  getFirestore,
  query,
  setDoc, where, updateDoc, getDoc,
} from 'firebase/firestore'

//Store/State tiedosto joka vastaa käyttäjien ja organisaatioiden luonnista
const actions = {
  //Action joka hakee backend:stä authorisation Token
  async fetchToken() {
    const urlToken = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyBKqNI8_VTBHymwxCmRjoGsbFmgaB5tsyw'
    const db = getFirestore()
    const docRef = doc(db, "tokens", 'fjKbw4hwwHacSpD9dsF7')
    const docSnap = await getDoc(docRef)
    const bodyToken = JSON.stringify({
      'email': docSnap.data().email,
      'password': docSnap.data().password,
      'returnSecureToken':true
    });
    const bodylength = bodyToken.toString().length

    return await fetch(urlToken, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodylength,
        'Host': 'identitytoolkit.googleapis.com',
        'Accept-Encoding': 'gzip,deflate,br',
        'Connection': 'keep-alive'
      },
      body: bodyToken,
    }).then(response => response.json())
      .then(data => {
        return data.idToken
      })
      .catch((error) => {
        console.error('Error:', error);
      })
  },
  //Action joka luo käyttäjä lähettämällä kutsu backend:lle
  async createUser({}, payload) {
    const db = getFirestore()
    const q = query(collection(db, "users"), where("username", "==", payload.user.username))
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.docs.length) {
      const urlData = 'https://europe-west1-ows-webedi-prod.cloudfunctions.net/app/api/createuser'
      const bodyData = JSON.stringify({
        email: payload.user.username,
        password: payload.user.password,
        firstname: payload.user.firstname,
        lastname: payload.user.lastname,
      });
      const bodylength = bodyData.toString().length
      await fetch(urlData, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer '+ payload.token,
          'Content-Type': 'application/json',
          'Content-Length': bodylength,
          'Host': 'europe-west1-ows-webedi-prod.cloudfunctions.net',
          'Accept-Encoding': 'gzip,deflate,br',
          'Connection': 'keep-alive'
        },
        body: bodyData
      }).then(response => response.json())
        .then(async response => {
          console.log(response)
          const db = getFirestore()
          const q = query(collection(db, "organisations"),
            where("name", "==", payload.user.organisation))
          const querySnapshot = await getDocs(q)
          const orgid = querySnapshot.docs[0].id
          const orgref = doc(db,'organisations', orgid)
          await setDoc(doc(db, "users", response.uid), {
            admin: false,
            disabled: false,
            username: payload.user.username,
            email: payload.user.username,
            firstname: payload.user.firstname,
            lastname: payload.user.lastname,
            phone: payload.user.phone,
            organisation: orgref
          });
        })
        .catch((error) => {
          return error
        });
    } else {
      return 'Käyttäjätunnus on jo varattu'
    }
  },
  //Action joka luo organisaatio lähettämällä kutsu backend:lle
  async createOgranisation({}, payload) {
    const db = getFirestore()

    const q = query(collection(db, "organisations"), where("partyid", "==", payload.partyid))
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.docs.length) {
      await addDoc(collection(db, "organisations"), {
        info: payload.info,
        name: payload.name,
        orgtype: payload.orgtype,
        partyid: payload.partyid
      })
    } else {
      return 'Organisaatio tällä osapuolitunnuksella on jo luotu'
    }
  },

  async createNotification({commit}, payload) {
    const db = await getFirestore()

    const docRef = await addDoc(collection(db, "notifications"), {
      date: payload.date,
      title: payload.title,
      text: payload.text
    });
    if (docRef.id) {
      return true;
    } else {
      return false
    }
  },
  async createFeedback({commit }, payload) {
    const urlData = 'https://europe-west1-ows-webedi-prod.cloudfunctions.net/app/api/createfeedback'
    const bodyData = JSON.stringify({
      email: payload.email,
      feedback: payload.feedback,
    });
    const bodylength = bodyData.toString().length
    await fetch(urlData, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+ payload.token,
        'Content-Type': 'application/json',
        'Content-Length': bodylength,
        'Host': 'europe-west1-ows-webedi-prod.cloudfunctions.net',
        'Accept-Encoding': 'gzip,deflate,br',
        'Connection': 'keep-alive'
      },
      body: bodyData
    }).then(response => response.json())
      .then(async response => {
        console.log(response)
    })
  }
}

export default {
  namespaced: true,
  actions
}
