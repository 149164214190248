const translate = {
  eng: {
    abbr: 'ENG',
    //Login page
    login: 'Login',
    username: 'Username',
    welcome: 'Welcome',
    choseLanguage: 'Choose language',
    forgotPassword: 'Forgot password?',
    password: 'Password',
    passwordReset: 'Do you want to reset your password?',
    passwordLink: 'Password link will be sent to your email',
    usernameRequired: 'Username is required',
    emailRequired: 'Username must looks like email',
    passwordRequired: 'Password is required',
    passwordRule: 'Password must contain at least 8 characters',
    errorManyTries: 'A lot of logins, try again later.',
    errorLogin: 'Wrong username/password',
    //Menu titles
    homepage: 'Home page',
    orders: 'Orders',
    profile: 'Profile',
    notifications: 'Notifications',
    users: 'Users',
    organisations: 'Organisations',
    instructions: 'Instructions',
    logout: 'Logout',
    columns: 'Change columns',
    feedback: 'Feedback',
    feedbackTitle: 'Send us feedback',
    feedbackText: 'We want to hear your opinion and ideas on the order portal. Please note that this form cannot be used for feedback related to individual orders.',
    //Dashboard page
    hello: 'Hello',
    newOrders: 'New orders',
    allOrders: 'All orders',
    orderNumber: 'Order no.',
    orderDate: 'Order date',
    deliveryDate: 'Delivery date',
    invoiceCustomer: 'Invoice customer',
    deliveryCustomer: 'Delivery customer',
    orderStatus: 'Order status',
    orderManager: 'Manager',
    actions: 'Actions',
    search: 'Search',
    open: 'Open',
    print: 'Print',
    new: 'New',
    canceled: 'Canceled',
    delivered: 'Delivered',
    accepted: 'Accepted',
    confirmed: 'Confirmed',
    //Single order page
    line: 'Line',
    productNumber: 'Product number',
    productName: 'Product name',
    orderAmount: 'Ordered amount',
    confirmedAmount: 'Confirmed amount',
    deliveryAmount: 'Delivery amount',
    unit: 'Unit',
    price: 'Price',
    summ: 'Sum',
    notes: 'Notes',
    originalLine: 'Orig. row',
    differ: 'Difference',
    order: 'Order',
    acceptOrder: 'Accept order',
    cancelOrder: 'Cancel order',
    wantToCancelText: 'Do you want to cancel the order?',
    no: 'No',
    yes: 'Yes',
    sendOrderConfirmation: 'Send order confirmation',
    sendDeliveryConfirmation: 'Send delivery confirmation',
    close: 'Close',
    deliveryAddress: 'Delivery address',
    desiredDeliveryDate: 'Desired delivery date',
    orderTotal: 'Order total',
    billingInformation: 'Billing information',
    orderReference: 'Order reference',
    cancel: 'Cancel',
    additionInfo: 'Additional information',
    orderDetails: 'Order details',
    orderLines: 'Order lines',
    wantToSendOrderConfirm: 'Do you want to send the order confirmation?',
    wantToSendDeliveryConfirm: 'Do you want to send the delivery confirmation?',
    send: 'Send',
    diffLines: 'These lines differ from the order',
    wantToSaveAllDates: 'Do you want to update the same delivery date to all rows?',
    rowsPerPage: 'Rows per page: ',
    productsPerPage: 'Products per page:',
    defaultView: 'Default view',
    //Profile page
    ownProfile: 'My profile',
    organisation: 'Organisation',
    firstname: 'First name',
    lastname: 'Last name',
    emailAddress: 'Email',
    phoneNumber: 'Phone number',
    changePassword: 'Change password',
    wantToChangePassword: 'Do you want to change your password?',
    wantToChangePasswordText: 'Password link will be sent to your email.',
    logoutText: 'After that you will be logged out.',
    //Users page
    status: 'Status',
    active: 'Active',
    disabled: 'Disabled',
    addUser: '+ Add user',
    updateUser: 'Update user',
    newUser: 'New user',
    mandatoryField: '*Mandatory field',
    save: 'Save',
    userNameRules: 'Name must be less than 50 characters',
    userEmailRule: 'Username must be email',
    userPhoneRule: 'Phone number must be max 10 characters',
    fieldIsRequired: 'Field is required',
    accept: 'Vahvista',
    //Organisation page
    type: 'Type',
    partyid: 'Party ID',
    supplier: 'Supplier',
    customer: 'Customer',
    updateOrganisation: 'Update organisation',
    name: 'Name',
    addOrganisation: '+ Add organisation',
    newOrganisation: 'New organisation',
    organisationName: 'Organisation name*',
    organisationType: 'Organisation type*',
    //Notification page
    version: 'Version',
    date: 'Date',
    updateLog: 'Update log',
    deleteNotifDialog: 'Do you want to delete this notification ?',
    changes: 'Updates',
    title: 'Title'
  },
  fi: {
    abbr: 'FI',
    //Login page
    login: 'Kirjaudu',
    username: 'Käyttäjätunnus',
    welcome: 'Tervetuloa',
    choseLanguage: 'Valitse kieli',
    forgotPassword: 'Unohtuiko salasana?',
    password: 'Salasana',
    passwordReset: 'Haluatko palauttaa salasanasi?',
    passwordLink: 'Salasanan vaihtolinkki lähetetään sähköpostitse.',
    usernameRequired: 'Käyttäjätunnus on pakollinen',
    emailRequired: 'Tunnuksen pitää olla sähköpostiosoite',
    passwordRequired: 'Salasana on pakollinen',
    passwordRule: 'Salasana pitää olla vähintään 8 merkkinen',
    errorManyTries: 'Liian monta kirjautumisyritystä. Odota hetki.',
    errorLogin: 'Virheellinen käyttäjätunnus/salasana',
    //Menu titles
    homepage: 'Etusivu',
    orders: 'Tilaukset',
    profile: 'Profiili',
    notifications: 'Ilmoitukset',
    users: 'Käyttäjät',
    organisations: 'Organisaatiot',
    instructions: 'Ohjeet',
    logout: 'Kirjaudu ulos',
    columns: 'Valitse sarakkeet',
    feedback: 'Palaute',
    feedbackTitle: 'Lähetä meille palautetta',
    feedbackText: 'Haluamme kuulla mielipiteesi portaalin toiminnasta. Jos kyseessä on tilaukseen liittyvä palaute, ole yhteydessä suoraan tilaajaorganisaatioon.',
    //Dashboard page
    hello: 'Hei',
    newOrders: 'Uudet tilaukset',
    allOrders: 'Kaikki tilaukset',
    orderNumber: 'Tilausnumero',
    orderDate: 'Tilauspäivä',
    deliveryDate: 'Toimituspäivä',
    invoiceCustomer: 'Tilaaja',
    deliveryCustomer: 'Toimitusasiakas',
    orderStatus: 'Tilauksen tila',
    orderManager: 'Käsittelijä',
    actions: 'Toiminnot',
    search: 'Hae',
    open: 'Avaa',
    print: 'Tulosta',
    new: 'Uusi',
    canceled: 'Peruutettu',
    delivered: 'Toimitettu',
    accepted: 'Käsittelyssä',
    confirmed: 'Vahvistettu',
    //Single order page
    line: 'Rivi',
    productNumber: 'Tuotenro',
    productName: 'Tuotenimi',
    orderAmount: 'Tilausmäärä',
    confirmedAmount: 'Vahvistettu määrä',
    deliveryAmount: 'Toimitettu määrä',
    unit: 'Yksikkö',
    price: 'Hinta',
    summ: 'Yhteensä',
    notes: 'Lisätiedot',
    originalLine: 'Alkup. rivi',
    differ: 'Erotus',
    order: 'Tilaus',
    acceptOrder: 'Ota käsittelyyn',
    cancelOrder: 'Peruuta tilaus',
    wantToCancelText: 'Haluatteko peruuttaa tilaus?',
    no: 'Ei',
    yes: 'Kyllä',
    sendOrderConfirmation: 'Lähetä tilausvahvistus',
    sendDeliveryConfirmation: 'Lähetä toimitusvahvistus',
    close: 'Sulje',
    deliveryAddress: 'Toimitusosoite',
    desiredDeliveryDate: 'Toivottu toimituspäivä',
    orderTotal: 'Tilauksen summa',
    billingInformation: 'Laskutustiedot',
    orderReference: 'Tilausviite',
    cancel: 'Peruuta',
    additionInfo: 'Lisätiedot',
    orderDetails: 'Tilauksen lisätiedot',
    orderLines: 'Tilausrivit',
    wantToSendOrderConfirm: 'Haluatko lähettää tilausvahvistuksen?',
    wantToSendDeliveryConfirm: 'Haluatko lähettää toimitusvahvistuksen?',
    send: 'Lähetä',
    diffLines: 'Nämä rivit eroavat tilaukselta',
    wantToSaveAllDates: 'Haluatko päivittää kaikille riveille saman toimituspäivän?',
    rowsPerPage: 'Rivejä sivulla: ',
    productsPerPage: 'Tuotteita sivulla:',
    defaultView: 'Oletusnäkymä',
    //Profile page
    ownProfile: 'Oma profiili',
    organisation: 'Organisaatio',
    firstname: 'Etunimi',
    lastname: 'Sukunimi',
    emailAddress: 'Sähköpostiosoite',
    phoneNumber: 'Puhelinnumero',
    changePassword: 'Vaihda salasana',
    wantToChangePassword: 'Haluatko vaihtaa salasanasi?',
    wantToChangePasswordText: 'Salasanan vaihtolinkki lähetetään sähköpostitse.',
    logoutText: 'Tämän jälkeen sinut kirjataan ulos.',
    //Users page
    status: 'Tila',
    active: 'Käytössä',
    disabled: 'Ei käytössä',
    addUser: '+ Lisää käyttäjä',
    updateUser: 'Muokkaa käyttäjää',
    newUser: 'Uusi käyttäjä',
    mandatoryField: '*Pakolliset kentät',
    save: 'Tallenna',
    userNameRules: 'Nimi pitää olle max 10 merkkinen',
    userEmailRule: 'Tunnus pitää olla sähköposti',
    userPhoneRule: 'Numero pitää olla max 10 merkkinen',
    fieldIsRequired: 'Kenttä on pakollinen',
    accept: 'Vahvista',
    //Organisation page
    type: 'Tyyppi',
    partyid: 'Osapuolitunnus',
    supplier: 'Toimittaja',
    customer: 'Tilaaja',
    updateOrganisation: 'Muokkaa organisaatio',
    name: 'Nimi',
    addOrganisation: '+ Lisää organisaatio',
    newOrganisation: 'Uusi organisaatio',
    organisationName: 'Organisaation nimi*',
    organisationType: 'Organisaation tyyppi*',
    //Notification page
    version: 'Versio',
    date: 'Päivämäärä',
    updateLog: 'Päivitysloki',
    deleteNotifDialog: 'Haluasto poistaa tämän ilmoituksen ?',
    changes: 'Päivitykset',
    title: 'Otsikko'
  }
}

export default translate;
