import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import "./plugins/vuetify-money.js";
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
import localforage from 'localforage'
import moment from 'moment'

//Pää js tiedosto, tässä ovat rekisteröity kaikki modulit/pluginit ja firebase
import * as firebase from "firebase/app"
import { getFirestore } from "firebase/firestore"

Vue.config.productionTip = false
Vue.prototype.moment = moment

Vue.use(localforage)

//Tässä on firebase configuraatio, millä saadaan yhteyttä firebasille
const firebaseConfig = {
  apiKey: "AIzaSyBKqNI8_VTBHymwxCmRjoGsbFmgaB5tsyw",
  authDomain: "ows-webedi-prod.firebaseapp.com",
  databaseURL: "https://ows-webedi-prod-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "ows-webedi-prod",
  storageBucket: "ows-webedi-prod.appspot.com",
  messagingSenderId: "91518966264",
  appId: "1:91518966264:web:376db0d289a414aa805e0d",
  measurementId: "G-XM95DNVFVM"
};

sync(store, router)

const app = firebase.initializeApp(firebaseConfig);
const db = getFirestore(app)

//Tässä implementoidaan kaikki modulit vue apille, eli koko ohjelmalle.
new Vue({
  router,
  vuetify,
  store,
  moment,
  localforage,
  render: h => h(App),
}).$mount('#app')
