import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VThemeProvider } from 'vuetify/lib/components/VThemeProvider';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-card',_vm._b({staticClass:"v-card--material mt-4"},'app-card',_vm.$attrs,false),[_c(VCardTitle,{staticClass:"title_block align-start"},[_c(VSheet,{staticClass:"overflow-hidden mt-n9 transition-swing v-card--material__sheet",attrs:{"color":_vm.color,"width":_vm.fullHeader ? '100%' : undefined,"elevation":"6","max-width":"100%","rounded":""}},[(_vm.hasHeading)?_c(VThemeProvider,{attrs:{"dark":""}},[(_vm.icon)?_c('div',{class:_vm.iconSmall ? 'pa-5' : 'pa-6'},[_c(VIcon,{attrs:{"large":!_vm.iconSmall},domProps:{"textContent":_vm._s(_vm.icon)}})],1):_vm._e(),_vm._t("heading"),(_vm.heading)?_c('div',{staticClass:"text-h4 white--text pa-7 v-card--material__title"},[_vm._v(" "+_vm._s(_vm.heading)+" ")]):_vm._e()],2):_vm._e()],1),(_vm.hasTitle)?_c('div',{staticClass:"card_title_text v-card--material__title",class:_vm.fullHeader ? 'pt-4' : 'pl-3'},[_vm._t("title"),(_vm.title)?[_vm._v(" "+_vm._s(_vm.title)+" ")]:_vm._e(),_c('div',{staticClass:"text-subtitle-1 mb-n4"},[_vm._t("subtitle"),(_vm.subtitle)?[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]:_vm._e()],2)],2):_vm._e()],1),_vm._t("default"),(_vm.$slots.actions)?[_c(VDivider,{staticClass:"mt-2 mx-4"}),_c(VCardActions,{staticClass:"px-4 text-caption grey--text"},[_vm._t("actions")],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }