import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c('material-card',{staticClass:"d-flex flex-wrap",attrs:{"icon":"mdi-clipboard-text","icon-small":"","color":"accent","title":_vm.langs[_vm.localization].newOrders}},[_c(VCardTitle,{staticClass:"search_block"},[_c(VSpacer),_c('div',{staticClass:"search_field d-flex"},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":_vm.langs[_vm.localization].search,"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('OrderHeadersDialog')],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.neworders,"search":_vm.search,"footer-props":{
           'items-per-page-text': _vm.langs[_vm.localization].rowsPerPage
      }},scopedSlots:_vm._u([{key:"item.orderdate",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.orderdate))+" ")]}},{key:"item.deliverydate",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.deliverydate))+" ")]}},{key:"item.invoicecustomer",fn:function({ item }){return [_vm._v(" "+_vm._s(item.invoicecustomer.name)+" ")]}},{key:"item.orderstatus",fn:function({ item }){return [(item.orderstatus == 'new')?_c(VChip,{attrs:{"color":"red","label":""}},[_vm._v(" "+_vm._s(_vm.langs[_vm.localization].new)+" ")]):_vm._e(),(item.orderstatus == 'open')?_c(VChip,{attrs:{"color":"blue","label":""}},[_vm._v(" "+_vm._s(_vm.langs[_vm.localization].open)+" ")]):_vm._e(),(item.orderstatus == 'canceled')?_c(VChip,{attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.langs[_vm.localization].canceled)+" ")]):_vm._e(),(item.orderstatus == 'delivered')?_c(VChip,{attrs:{"label":"","color":"green"}},[_vm._v(" "+_vm._s(_vm.langs[_vm.localization].delivered)+" ")]):_vm._e(),(item.orderstatus == 'accepted')?_c(VChip,{attrs:{"label":"","color":"yellow"}},[_vm._v(" "+_vm._s(_vm.langs[_vm.localization].accepted)+" ")]):_vm._e(),(item.orderstatus == 'confirmed')?_c(VChip,{attrs:{"label":"","color":"cyan lighten-2"}},[_vm._v(" "+_vm._s(_vm.langs[_vm.localization].confirmed)+" ")]):_vm._e()]}},{key:"item.actions",fn:function({ item }){return [_c('td',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":{ name: 'OrderView', params: {id: item.orderid}}}},[_c(VBtn,{staticClass:"ml-2 mt-3",attrs:{"height":"23","color":"primary"}},[_vm._v(" "+_vm._s(_vm.langs[_vm.localization].open)+" ")])],1),_c(VBtn,{staticClass:"ml-2 mt-3",attrs:{"height":"23","color":"primary"},on:{"click":function($event){return _vm.printDoc(item)}}},[_vm._v(" "+_vm._s(_vm.langs[_vm.localization].print)+" ")])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }