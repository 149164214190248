
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  browserSessionPersistence,
  signOut,
} from 'firebase/auth'
import translate from '@/plugins/translation'

import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  addDoc, collection,
} from 'firebase/firestore'

import { dispatch, make } from 'vuex-pathify'

//Store/State tiedosto joka vastaa kirjautumisesta ja userin validoinnista
const state = {
  user: '',
  userName: '',
  admin: null,
  orgName: '',
  partyID: '',
  orgtype: '',
  localization: 'fi',
  defaultHeaders: [
    {
      text: 'line',
      value: 'id',
    },
    {
      text: 'productNumber',
      value: 'productno_supplier'
    },
    {
      text: 'EAN',
      value: 'eancode'
    },
    {
      text: 'productName',
      value: 'productname'
    },
    {
      text: 'orderAmount',
      value: 'order_amount'
    },
    {
      text: 'confirmedAmount',
      value: 'confirmed_amount'
    },
    {
      text: 'unit',
      value: 'unit'
    },
    {
      text: 'price',
      value: 'unitprice'
    },
    {
      text: 'summ',
      value: 'rowsum'
    },
    {
      text: 'deliveryDate',
      value: 'deliverydate'
    },
    {
      text: 'notes',
      value: 'notes',
    }
  ],
  defaultOrdersHeaders: [
    {
      text: 'orderNumber',
      value: 'ordernumber',
    },
    {
      text: 'orderDate',
      value: 'orderdate',
    },
    {
      text: 'deliveryDate',
      value: 'deliverydate',
    },
    {
      text: 'invoiceCustomer',
      value: 'invoicecustomer',
    },
    {
      text: 'supplier',
      value: 'supplier.name',
    },
    {
      text: 'deliveryCustomer',
      value: 'deliverycustomer.name',
    },
    {
      text: 'orderStatus',
      value: 'orderstatus',
    },
    {
      text: 'orderManager',
      value: 'ordermanager',
    },
    {
      text: 'actions',
      value: 'actions',
      sortable: false
    }
  ],

  headers: [
    {
      text: 'line',
      value: 'id',
    },
    {
      text: 'productNumber',
      value: 'productno_supplier'
    },
    {
      text: 'EAN',
      value: 'eancode'
    },
    {
      text: 'productName',
      value: 'productname'
    },
    {
      text: 'orderAmount',
      value: 'order_amount'
    },
    {
      text: 'confirmedAmount',
      value: 'confirmed_amount'
    },
    {
      text: 'unit',
      value: 'unit'
    },
    {
      text: 'price',
      value: 'unitprice'
    },
    {
      text: 'summ',
      value: 'rowsum'
    },
    {
      text: 'deliveryDate',
      value: 'deliverydate'
    },
    {
      text: 'notes',
      value: 'notes',
    }
  ],
  ordersHeaders: [
    {
      text: 'orderNumber',
      value: 'ordernumber',
    },
    {
      text: 'orderDate',
      value: 'orderdate',
    },
    {
      text: 'deliveryDate',
      value: 'deliverydate',
    },
    {
      text: 'invoiceCustomer',
      value: 'invoicecustomer',
    },
    {
      text: 'supplier',
      value: 'supplier.name',
    },
    {
      text: 'deliveryCustomer',
      value: 'deliverycustomer.name',
    },
    {
      text: 'orderStatus',
      value: 'orderstatus',
    },
    {
      text: 'orderManager',
      value: 'ordermanager',
    },
    {
      text: 'actions',
      value: 'actions',
      sortable: false
    }
  ]
}

const getters = {
  getHeaders(state) {
    let obj = []
    let element
    state.headers.forEach(el => {
      if (translate[state.localization][el.text]) {
        element = translate[state.localization][el.text]
      } else if (el.text == 'EAN') {
        element = 'EAN'
        el.value = 'eancode'
      }
      obj.push({text: element, value: el.value})
    })
    return obj
  },
  getOrdersHeaders(state) {
    let obj = []
    let element
    state.ordersHeaders.forEach(el => {
      if (translate[state.localization][el.text]) {
        element = translate[state.localization][el.text]
      }
      obj.push({text: element, value: el.value})
    })
    return obj
  }
}

const mutations = {
  ...make.mutations(state),
  mutateHeaders(state, headers) {
    let index = 0
    let changedArr = [0, 4, 5, 9]
    let newHeaders = []

    for (let i = 0; i < headers.length; i++) {
      index = state.defaultHeaders.findIndex(object => {
        return object.value === headers[i].value
      })
      if (!changedArr.includes(index)) {
        changedArr.push(index)
      }
    }

    function sorter(a, b) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    }

    changedArr = changedArr.sort(sorter)

    for(let i = 0; i < changedArr.length; i++) {
      newHeaders.push(state.defaultHeaders[changedArr[i]])
    }

    state.headers = newHeaders
  },
  mutateOrderHeaders(state, headers) {
    let index = 0
    let changedArr = [0, 6, 8]
    let newHeaders = []

    for (let i = 0; i < headers.length; i++) {
      index = state.defaultOrdersHeaders.findIndex(object => {
        return object.value === headers[i].value
      })
      if (!changedArr.includes(index)) {
        changedArr.push(index)
      }
    }

    function sorter(a, b) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    }

    changedArr = changedArr.sort(sorter)
    for(let i = 0; i < changedArr.length; i++) {
      newHeaders.push(state.defaultOrdersHeaders[changedArr[i]])
    }
    state.ordersHeaders = newHeaders
  }
}

const actions = {
  //Aktion joka tarkistaa rooli ja tallentaa ylös käyttäjän nimi + organisaation ID
  async checkRole ( { commit, state } ) {
    commit('partyID', '')
    commit('user', '')
    const auth = await getAuth()
    const db = await getFirestore()
    const docRef = doc(db, "users", auth.currentUser.uid)
    const docSnap = await getDoc(docRef)
    const username = await docSnap.data().firstname + ' ' + docSnap.data().lastname
    const userobj = docSnap.data()
    if (docSnap.data().lang) {
      const lang = docSnap.data().lang
      commit('localization', lang)
    } else {
      const auth = await getAuth()
      const db = await getFirestore()

      const user = doc(db, "users", auth.currentUser.uid)
      await updateDoc(user, {
        lang: state.localization
      })
    }

    if (docSnap.data().ordersHeaders) {
      const headers = docSnap.data().ordersHeaders
      commit('ordersHeaders', headers)
    }

    if (docSnap.data().headers) {
      const headers = docSnap.data().headers
      commit('headers', headers)
    }


    commit('userName', username)

    if (docSnap.data().admin) {
      commit('admin', true);
    }

    const organisation = docSnap.data().organisation
    if (organisation) {
      const docRef = doc(db, "organisations", organisation.id)
      const docSnap = await getDoc(docRef)
      const partyid = docSnap.data().partyid
      const name = docSnap.data().name
      const orgtype = docSnap.data().orgtype
      const orgname = docSnap.data().name
      userobj.orgname = name
      commit('orgName', orgname)
      commit('orgtype', orgtype)
      commit('user', userobj)
      commit('partyID', partyid)
      return partyid
    }
  },
  //Acition joka tarkistaa onko käyttäjä Admin
  async checkAdmin({commit}) {
    commit('admin', false)
    const auth = await getAuth()
    const db = await getFirestore()
    const docRef = doc(db, "users", auth.currentUser.uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.data().admin) {
      commit('admin', docSnap.data().admin)
      return true
    } else {
      return false
    }
  },
  //Kirjautumis action, joka pitää kirjautumis sessio
  async login ({ commit, disptach, state }, { email, password }) {
    try {
      const auth = getAuth()
      const resp = await setPersistence(auth, browserSessionPersistence)
        .then(async () => {
          return signInWithEmailAndPassword(auth, email, password)
        })
        .catch((error) => {
          const errorCode = error.code
          const errorMessage = error.message
          return errorCode
        });

      if(resp?.user?.uid) {
        let token = await dispatch('create/fetchToken')
        const urlData = 'https://europe-west1-ows-webedi-prod.cloudfunctions.net/app/api/createLoginLog'
        const bodyData = JSON.stringify({
          email: email,
          timestamp: new Date(),
          status: typeof resp === 'string' ? resp : 'success'
        });
        const bodylength = bodyData.toString().length
        fetch(urlData, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Content-Length': bodylength,
            'Host': 'europe-west1-ows-webedi-prod.cloudfunctions.net',
            'Accept-Encoding': 'gzip,deflate,br',
            'Connection': 'keep-alive'
          },
          body: bodyData
        }).then((response) => {
          console.log(response)
        })
        console.log('login')
      } else if(typeof resp === 'string') {
        const db = await getFirestore()
        addDoc(collection(db, "logs"), {
          email: email,
          timestamp: new Date(),
          status: resp
        }).then(r => {
          console.log('login')
        })
      }
      console.log('save logs')
      return resp
    } catch (e) {
      throw e
    }
  },

  async logout ({commit}) {
    const auth = getAuth();
    signOut(auth).then(() => {
      commit('user', '')
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });
  },

  async resetPassword ({commit}, payload) {
    const auth = getAuth()
    sendPasswordResetEmail(auth, payload.emailAdress)
  },

  async updateLocalization({commit}, payload) {
    commit('localization', payload.localization)
  },
  updateHeaders({commit}, payload) {
    commit('mutateHeaders', payload.headers)
  },
  updateOrderHeaders({commit}, payload) {
    commit('mutateOrderHeaders', payload.headers)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
