<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="grey"
          elevation="2"
          icon
          small
          v-bind="attrs"
          v-on="on"
          class="mt-4 ml-2"
        >
          <v-icon
            dark
          >
            mdi-view-column
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>{{ langs[localization].columns }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-checkbox
                input-value="true"
                disabled
                :label="langs[localization].orderNumber"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="checkboxIndex['orderdate']"
                :label="langs[localization].orderDate"
                color="indigo darken-3"
                :value="true"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="checkboxIndex['deliverydate']"
                :label="langs[localization].deliveryDate"
                color="indigo darken-3"
                :value="true"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-checkbox
                v-model="checkboxIndex['supplier.name']"
                :label="langs[localization].supplier"
                color="indigo darken-3"
                :value="true"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="checkboxIndex['invoicecustomer']"
                :label="langs[localization].invoiceCustomer"
                color="indigo darken-3"
                :value="true"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="checkboxIndex['deliverycustomer.name']"
                :label="langs[localization].deliveryCustomer"
                color="indigo darken-3"
                :value="true"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-checkbox
                input-value="true"
                disabled
                :label="langs[localization].orderStatus"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="checkboxIndex['ordermanager']"
                :label="langs[localization].orderManager"
                color="indigo darken-3"
                :value="true"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
            color="blue darken-1"
            text
            @click="defaultHeaders()"
          >
            {{ langs[localization].defaultView }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            {{ langs[localization].cancel }}
          </v-btn>
          <v-btn
            color="primary"
            @click="updateHeaders"
          >
            {{ langs[localization].save }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import translation from '@/plugins/translation'

export default {
  name: 'OrderHeadersDialog',
  data () {
    return {
      langs: translation,
      dialogm1: '',
      dialog: false,
      checkboxIndex: [],
      headers: [
        {
          text: 'orderNumber',
          value: 'ordernumber',
        },
        {
          text: 'orderDate',
          value: 'orderdate',
        },
        {
          text: 'deliveryDate',
          value: 'deliverydate',
        },
        {
          text: 'invoiceCustomer',
          value: 'invoicecustomer',
        },
        {
          text: 'supplier',
          value: 'supplier.name',
        },
        {
          text: 'orderStatus',
          value: 'orderstatus',
        },
        {
          text: 'deliveryCustomer',
          value: 'deliverycustomer.name',
        },
        {
          text: 'orderManager',
          value: 'ordermanager',
        },
        {
          text: 'actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  mounted () {
    this.computeHeaders()
  },
  computed: {
    ...mapState({
      stateOrdersHeaders: state => state.auth.ordersHeaders,
      localization: state => state.auth.localization
    }),
  },
  methods: {
    updateHeaders() {
      let arr = []
      for (let i = 0; i < this.headers.length; i++) {
        if (this.checkboxIndex[this.headers[i].value] == true) {
          arr.push(this.headers[i])
        }
      }
      this.$store.dispatch('auth/updateOrderHeaders', {
        headers: arr
      })
      this.dialog = false
      this.$store.dispatch('update/updateUserOrdersHeaders', {
        headers: this.stateOrdersHeaders
      })
    },
    computeHeaders () {
      let newHeaders = this.stateOrdersHeaders
      for(let i = 0; i < newHeaders.length; i++) {
        this.checkboxIndex[newHeaders[i].value] = true
      }
    },
    defaultHeaders() {
      this.$store.dispatch('auth/updateOrderHeaders', {
        headers: this.headers
      })
      this.dialog = false
      this.$store.dispatch('update/updateUserOrdersHeaders', {
        headers: this.headers
      })
      this.computeHeaders()
    }
  }
}
</script>

<style scoped>

</style>
