// Pathify
import { make } from 'vuex-pathify'
import translate from '@/plugins/translation'


//Pää tiedosto missä rekisteröidään valikkon välilehtiä ja layout ominaisuuksia
// Data
const state = {
  drawerImage: true,
  mini: false,
  items: [
    {
      title: 'homepage',
      icon: 'mdi-view-dashboard',
      to: '/dashboard',
    },
    {
      title: 'orders',
      icon: 'mdi-clipboard-text',
      to: '/orders',
    },
    {
      title: 'profile',
      icon: 'mdi-account',
      to: '/profile',
    },
    {
      title: 'notifications',
      icon: 'mdi-bell',
      to: '/notifications',
    },
    {
      admin_title: 'users',
      icon: 'mdi-account-group',
      to: '/users'
    },
    {
      admin_title: 'organisations',
      icon: 'mdi-home-group',
      to: '/organisations'
    },
    {
      title: 'instructions',
      icon: 'mdi-book-open-variant',
      to: '/instructions'
    }
  ],
  dark: true,
  drawer: {
    image: 0,
    gradient: 0,
    mini: false,
  },
  gradients: [
    'rgba(44, 86, 151, 1), rgba(0, 163, 224, 0.7)'
  ],
  notifications: [],
  rtl: false,
}

const mutations = make.mutations(state)

const getters = {
  dark: (state, getters) => {
    return (
      state.dark
    )
  },
  gradient: state => {
    return state.gradients[0]
  },
  image: state => {
    return state.drawer.image === '' ? state.drawer.image : state.images[state.drawer.image]
  },
}

const actions = {
  ...make.actions(state),
  init: ({ commit }) => {

  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
