<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
  // Styles
  import '@/styles/overrides.sass'
  //Pää komponenti, tälle äpille. Tässä komponentissä olevat tyylit ovat käytettävissä koko projektissa
  export default {
    name: 'App',
    metaInfo: {
      title: 'App',
      titleTemplate: 'Order Portal',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    }
  }
</script>
<style>
.search_field  {
  width: 200px !important;
}
.v-data-table {
  width: 100%;
}
.orderview_head {
  width: 25%;
}
.row {
  margin: 0px !important;
}
.v-chip {
  width: 100% !important;
  height: 23px !important;
  justify-content: center;
  font-weight: 500 !important;
}

@media only screen and (max-width: 1920px) {
  th {
    max-width: 5vw !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  th:hover{
    overflow: visible !important;
    white-space: nowrap !important;
    padding-right: 100px !important;
  }
  th:last-child:hover {
    padding-right: 0px !important;
  }
  .text-start {
    font-size: 13px !important;
    margin-left: 10px !important;
  }
  .order_table p {
    margin: 0;
    font-size: 11px;
  }
}

@media only screen and (max-width: 800px) {
  .container {
    width: 100% !important;
    padding: 4px !important;
  }
  .orderview_head {
    margin-top: 20px;
    width: 50%;
  }
}
@media only screen and (max-width: 600px) {
  .search_field  {
    width: 100% !important;
  }
}
@media only screen and (max-width: 471px) {
  .orderview_head {
    margin-top: 10px;
    width: 100%;
  }
}
</style>
